const deutsch = {
    'CRAFT.Type_Logistics' : 'Logistik { number }',
    'CRAFT.Type_Floor' : 'Boden { number }',
    'CRAFT.Type_Assembly' : 'Montage { number }',
    'CRAFT.Type_Electrics' : 'Elektrik { number }',
    'CRAFT.Type_Graphic' : 'Grafik { number } ',
    'CRAFT.Type_Furniture' : 'Möbel { number }',
    'CRAFT.Type_Multimedia' : 'Multimedia { number }',
    'CRAFT.Type_Cleaning' : 'Reinigung { number }',
    'CRAFT.Type_Additional' : 'Sonstige { number }',
    'CRAFT.Type_MPControl' : 'MP Kontrolle',
    'CRAFT.Type_Protocol' : 'Protokoll',
    'CRAFT.Type_Service' : 'Service { number }',
    'CRAFT.Type_CollectGraphics' : 'Grafik einlagern',
    'CRAFT.Status_Text_InProgress' : 'In Bearbeitung',
    'CRAFT.Status_Text_Disabled' : 'Gesperrt',
    'CRAFT.Status_Text_Completed' : 'Fertiggestellt',
    'CRAFT.Status_Text_Overdue' : 'Überfällig',
    'CRAFT.Status_Text_Done' : 'Grafik demontiert',
    'CRAFT.Status_Text_Open' : 'noch offen',
    'CRAFT.Status_Text_Collected' : 'Grafik demontiert',
    'CRAFT.Status_Warning_Cannot_Change_Done': 'Status kann nicht geändert werden: die Grafik wurde bereits im Admin-Panel bearbeitet',
    'CRAFT.Button_Text_MarkDone' : 'Fertiggestellt',
    'CRAFT.Button_Text_MarkInProgress' : 'In Bearbeitung',
    'CRAFT.Button_ViewAll' : 'Alle anzeigen',
    'CRAFT.Button_Text_Print' : 'Drucken',
    'CRAFT.Button_Text_Stand' : 'Stand',
    'CRAFT.Button_ChangeStatus_InProgress' : 'In Bearbeitung',
    'CRAFT.Button_ChangeStatus_Done': 'Fertig',
    'CRAFT.Button_Text_ShelfNumber' : 'Regal Nr.',
    'CRAFT.Filter_Params_CraftType' : 'Gewerk: { withFilter }',
    'EXHIBITION.Select_Instruction' : 'Bitte eine Veranstaltung aus { br } folgender Liste wählen',
    'EXHIBITION.Select_Label' : 'Gewählte Veranstaltung',
    'EXHIBITION.Modal_Title' : 'Wähle eine Veranstaltung',
    'EXHIBITION.Timer_State_Underway' : 'Gestartet',
    'EXHIBITION.Timer_State_Ended' : 'Beendet',
    'EXHIBITION.Title_CraftTotal' : 'Gesamtübersicht Gewerke',
    'EXHIBITION.Statistic_Status_InTotal' : 'Insgesamt',
    'EXHIBITION.Statistic_Status_InProgress' : 'In Bearbeitung',
    'EXHIBITION.Statistic_Status_Done' : 'Fertig',
    'EXHIBITION.Statistic_Status_Overdue' : 'Überfällig',
    'EXHIBITION.Statistic_Status_NotApproved' : 'Nicht freigegeben',
    'EXHIBITION.Statistic_Type_Stands' : 'Stände',
    'EXHIBITION.Statistic_Type_Claims' : 'mp Reklamationen',
    'EXHIBITION.Statistic_Type_CustomerClaims' : 'Aussteller Reklamation',
    'EXHIBITION.Statistic_Type_LastMinute' : 'Last Minute',
    'EXHIBITION.Statistic_Type_MpControl' : 'MP Kontrolle',
    'EXHIBITION.Statistic_Type_Damages' : 'Schäden',
    'EXHIBITION.Statistic_Type_Protocol' : 'Protokoll',
    'FORM.Textarea_Placeholder_JobDescription' : 'Job-Beschreibung*',
    'FORM.Button_Text_AddJob' : 'Job hinzufügen',
    'FORM.Button_Text_SaveJob' : 'Job speichern ',
    'FORM.Button_AddJobPhoto' : 'Foto hinzufügen',
    'FORM_RULES.Required_Login' : 'Bitte Login eingeben',
    'FORM_RULES.Required_Password' : 'Bitte Passwort eingeben',
    'FORM_RULES.Required_KeyDescription' : 'Bitte Schlüsselbeschreibung eingeben',
    'FORM_RULES.Required_KeyNumber' : 'Bitte Schlüsselnummer eingeben',
    'FORM_RULES.Required_JobDescription' : 'Bitte Jobbeschreibung hinzufügen',
    'FORM_RULES.Required_Quantity' : 'Bitte Menge eingeben',
    'FORM_RULES.Min_Quantity' : 'Die Menge muss eine Zahl sein, die nicht kleiner als eins ist',
    'FORMS.Input_Label_Login' : 'Anmelden',
    'FORMS.Input_Label_Password' : 'Passwort',
    'FORMS.Button_Text_Login' : 'Login',
    'FORMS.Input_Label_Description' : 'Beschreibung',
    'FORMS.Input_Label_KeyNumber' : 'Schlüsselnummer',
    'FORMS.Input_Label_Hall' : 'Halle',
    'FORMS.Input_Label_Stands' : 'Stände',
    'FORMS.Button_Text_ApplyFilters' : 'Anwenden',
    'FORMS.Button_Text_ResetFilters' : 'Filter zurücksetzen',
    'FORMS.Select_DefaultValue_All' : 'Alles',
    'FORMS.Select_StandTypeValue_CraftInProgress' : 'mit Gewerk in Bearbeitung',
    'FORMS.Select_StandTypeValue_CraftCompleted' : 'mit abgeschlossenem Gewerk',
    'FORMS.Select_StandTypeValue_ControlInProgress' : 'mit Gewerk Kontrolle in Bearbeitung',
    'FORMS.Select_StandTypeValue_ProtocolInProgress' : 'mit Gewerk Protokoll in Bearbeitung',
    'FORMS.Select_StandTypeValue_OverdueCrafts' : 'mit überfälligen Gewerken',
    'FORMS.Select_StandTypeValue_AdditionalJobs' : 'mit zusätzlichen Jobs',
    'FORMS.Button_Text_ApplySort' : 'Anwenden',
    'FORMS.Input_Label_CraftType' : 'Gewerketyp',
    'FORMS.Input_Label_JobType' : 'Typ',
    'FORMS.Input_Label_JobFlag' : 'Flagge',
    'FORMS.Input_Label_JobStatus' : 'Status',
    'FORMS.Input_Label_CraftStatus' : 'Gewerkestatus',
    'FORMS.Input_Label_Subcontractor' : 'Subunternehmer',
    'FORMS.Select_JobStatusValue_JobsOpen' : 'Offen Jobs',
    'FORMS.Select_JobStatusValue_JobsInProgress' : 'Jobs in Bearbeitung',
    'FORMS.Select_JobStatusValue_FinishedJobs' : 'Abgeschlossene Jobs',
    'FORMS.Select_JobFlag_WithoutFlag' : 'Ohne Flagge',
    'FORMS.Select_JobFlag_WithFlag' : 'Mit Fahne',
    'FORMS.RadioInput_Text_Description' : 'Beschreibung',
    'FORMS.RadioInput_Text_Furnishing' : 'Ausstattung hinzufügen',
    'FORMS.RadioInput_Text_FurnishingClaim' : 'Ausstattungs Reklamation',
    'FORMS.Input_Label_FurnishingName' : 'Ausstattungs Artikel',
    'FORMS.Input_Label_FurnishingReturnQuantity' : 'Rücknahme Menge',
    'FORMS.Input_Label_Quantity' : 'Menge',
    'FORMS.Input_Label_CurrentQuantity' : 'Bestand aktuell',
    'FORMS.Input_Label_NewQuantity' : 'Bestand neu',
    'FORMS.Input_Label_OrderType'   : 'Änderungstyp',
    'FORMS.Select_FurnishingOrder_Type_Return' : "Artikel Rücknahme",
    'FORMS.Select_FurnishingOrder_Type_Lost' : "Verloren",
    'FORMS.Select_FurnishingOrder_Type_Correction' : "Gesamtmenge ändern",
    'FORMS.Select_FurnishingOrder_Type_Order' : "Befehl",
    'GLOBAL.Text_Piece' : 'st.',
    'GLOBAL.Button_Text_Logout' : 'Logout',
    'GLOBAL.Button_Text_Filter' : 'Filter',
    'GLOBAL.Button_Text_Sort' : 'Sortieren',
    'GLOBAL.Text_Version' : 'Version { versionNumber }',
    'GLOBAL.Button_Text_Cancel' : 'Abbrechen',
    'GLOBAL.Badge_Text_New' : 'NEU',
    'GLOBAL.Modal_Title_Filters' : 'Filter',
    'GLOBAL.Modal_Title_SortBy' : 'Sortieren nach',
    'GLOBAL.TimeLeft' : 'noch { timeLeft }',
    'GLOBAL.Button_Text_Confirm': 'Ändern',
    'GLOBAL.Card_Hero_Hall' : 'Halle',
    'GLOBAL.Card_Hero_Block' : 'Block',
    'GLOBAL.Card_Hero_Stand' : 'Stand',
    'HALL.Button_Text_HallPlan' : 'Hallenplan',
    'HALL.Link_Text_HallPlan' : '{ hallName } Hallenplan',
    'JOB.Tag_Claim' : 'R',
    'JOB.Tag_CustomerClaim' : 'R',
    'JOB.Tag_MPAndCustomerClaim' : 'R',
    'JOB.Tag_LastMinute' : 'L',
    'JOB.Tag_Damage' : 'S',
    'JOB.Tag_ProtocolDefect' : 'M',
    'JOB.Card_Hero_Hall' : 'Halle',
    'JOB.Card_Hero_Number' : 'Job Nr.',
    'JOB.Card_Hero_Block' : 'Block',
    'JOB.Card_Hero_Stand' : 'Stand',
    'JOB.Card_Hero_Exhibitor' : 'Aussteller',
    'JOB.Button_Text_ToTheStand' : 'Zum Stand',
    'JOB.Text_Creator' : 'Erstellt von { whoCreate } am { whenCreate }',
    'JOB.Text_Executor' : 'Bearbeitet von { whoExecute } am { whenExecute }',
    'JOB.Text_DurationTime' : 'Dauer: { time }',
    'JOB.Type_Claim' : 'mp Reklamation',
    'JOB.Type_AllClaims' : 'Reklamation',
    'JOB.Type_CustomerClaim' : 'Aussteller Reklamation',
    'JOB.Type_LastMinute' : 'Last Minute',
    'JOB.Type_Damage' : 'Schaden',
    'JOB.Type_ProtocolDefect' : 'Mangel',
    'JOB.Button_ChangeStatus_Waiting' : 'Job Annehmen',
    'JOB.Button_ChangeStatus_InProgress' : 'Job In Bearbeitung',
    'JOB.Button_ChangeStatus_Done' : 'Job Fertig',
    'JOB.Modal_Title_Photo' : 'Job Foto',
    'JOB.Filter_Params_JobType' : 'Typ: { withFilter }',
    'JOB.Filter_Params_CraftType' : 'Gewerk: { withFilter }',
    'JOB.Modal_Title_NewJob' : 'Neu { jobType }',
    'JOB.Modal_Title_EditJob' : 'Bearbeiten { jobType }',
    'JOB.Button_Text_AddClaim' : 'mp Reklamationsjob hinzufügen',
    'JOB.Button_Text_AddCustomerClaim' : 'Aussteller Reklamation job hinzufügen',
    'JOB.Button_Text_AddLastMinute' : 'Last Minute Job hinzufügen',
    'JOB.Button_Text_AddDamage' : 'Schaden erfassen',
    'JOB.Button_Text_AddProtocolDefect' : 'Mangel hinzufügen',
    'DAMAGE.Card_Title' : 'Schäden der { exhibitor }',
    'DAMAGE.Modal_Title_Photo' : '{ exhibitor } schäden',
    'DAMAGE.Status_Label_New' : 'Ungeprüft',
    'DAMAGE.Status_Label_Check' : '1. Prüfung',
    'DAMAGE.Status_Label_DCheck' : 'Finale Prüfung',
    'DAMAGE.Button_Text_Stand' : 'Stand',
    'DAMAGE.Button_Text_Photo' : 'Foto',
    'DAMAGE.Button_Text_AddNote' : "NOTIZ HINZUFÜGEN",
    'DAMAGE.Modal_Button_AddPhoto' : 'Foto hinzufügen',
    'DAMAGE.Modal_Button_CreateReport' : 'Schaden anlegen',
    'DAMAGE.Modal_Button_EditReport' : 'Schaden bearbeiten',
    'DAMAGE.AsCraft_label' : "Schaden",
    "DAMAGE.Button_Text_Open" : "Öffnen",
    "STAND.Block_Title_Damages" : "Schaden",
    'MENUS.Label_Overview' : 'Übersicht',
    'MENUS.Label_Stands' : 'Stände',
    'MENUS.Label_Jobs' : 'Jobs',
    'MENUS.Label_ExpoPlan' : 'Hallenplan',
    'MENUS.Label_More' : 'Mehr',
    'MENUS.Label_SelectExhibition' : 'Veranstaltung auswählen',
    'MENUS.Label_CraftsOverview' : 'Gewerkeübersicht',
    'MENUS.Label_HallsOverview' : 'Hallenübersicht',
    'MENUS.Label_ViewDamages' : 'Schäden anzeigen',
    'MENUS.Label_Downloads' : 'Downloads',
    'MENUS.Label_Documents' : 'Dokumente',
    'MENUS.Label_FurnishingLeftovers' : 'LM Ausstattung',
    'MENUS.Label_HelpImprint' : 'Impressum',
    'MENUS.Label_CollectGraphics' : 'Grafik einlagern',
    'NO_DATA.Exhibitions' : 'Dir wurden noch keine Veranstaltungen zugewiesen.',
    'NO_DATA.Documents' : 'Aktuelle Ausstellung hat noch keine { br } Dokumente.',
    'NO_DATA.Stands' : 'Es wurden keine Stände gefunden,  { br } die der Anforderung entsprechen.',
    'NO_DATA.StandPhoto' : 'Du hast noch kein Standfoto.',
    'NO_DATA.DamagePhoto' : 'Sie haben noch kein Foto der Schäden { br } an diesem Stand',
    'NO_DATA.Stand_NoKeys' : 'Dieser Stand hat noch keine Schlüssel.',
    'NO_DATA.Stand_DoesNotExist' : 'Dieser Stand existiert nicht.',
    'NO_DATA.Subcontractor_Unsigned' : 'Nicht zugewiesen',
    'NO_DATA.Jobs' : 'Es wurden keine Jobs gefunden,  { br } die der Anforderung entsprechen.',
    'NO_DATA.Damages' : 'Es wurden keine Schäden gefunden,  { br } die der Anforderung entsprechen.',
    'NO_DATA.Hall' : 'Diese Halle existiert nicht.',
    'NO_DATA.HallPlan' : 'Wir haben keinen Plan von dieser Halle gefunden.',
    'NO_DATA.Furnishing_Storage' : 'Diese Ausstellung hat { br } keine Möbel im Lager',
    'NO_DATA.Furnishing_Orders' : 'Diese Ausstellung hat { br } keine Einrichtungsaufträge',
    'PAGE.Title_Overview' : 'Übersicht',
    'PAGE.Title_ExhibitionSelection' : 'Veranstaltungswahl',
    'PAGE.Title_Stands' : 'Stände',
    'PAGE.Title_Stand' : 'Stand',
    'PAGE.Title_StandLog' : 'Stand Log',
    'PAGE.Title_StandKeys' : 'Standschlüssel',
    'PAGE.Title_ViewDamages' : 'Schäden anzeigen',
    'PAGE.Title_AdditionalMenu' : 'Zusätzliches Menü',
    'PAGE.Title_Jobs' : 'Jobs',
    'PAGE.Title_ExpoPlan' : 'Hallenplan',
    'PAGE.Title_Documents' : 'Dokumente',
    'PAGE.Title_FurnishingLeftovers' : 'LM Artikelübersicht',
    'PAGE.Title_FurnishingReturns' : 'Änderungs- Log',
    'PAGE.Title_HallPlan' : 'Hallenplan',
    'PAGE.Title_HallsOverview' : 'Hallenübersicht',
    'PAGE.Title_CraftsOverview' : 'Gewerkeübersicht',
    'PAGE.Title_HelpImprint' : 'Impressum',
    'PAGE.Title_CollectGraphics' : 'Grafik Einlagern',
    'SORT.Text_NewestByCreation' : 'Neuestes nach Erstellungsdatum',
    'SORT.Text_OldestByCreation' : 'Ältestes nach Erstellungsdatum',
    'SORT.Text_ExhibitorNameAZ' : 'Aussteller (A-Z)',
    'SORT.Text_ExhibitorNameZA' : 'Aussteller (Z-A)',
    'SORT.Text_FinishedFirst' : 'Zuerst fertiggestellt',
    'SORT.Text_InProgressFirst' : 'Zuerst offen',
    'SORT.Text_StandNumberAZ' : 'Stand nummer (A-Z)',
    'SORT.Text_StandNumberZA' : 'Stand nummer (Z-A)',
    'SORT.Text_CorridorAZ' : 'Gang (A-Z)',
    'SORT.Text_CorridorZA' : 'Gang (Z-A)',
    'SORT.Text_BlockAZ' : 'Block (A-Z)',
    'SORT.Text_BlockZA' : 'Block (Z-A)',
    'SORT.Text_FlagFirst' : 'Zuerst mit Flagge',
    'SORT.Text_FlagLast' : 'Mit Flagge zuletzt',
    'STAND.Modal_Title_Search' : 'Suche',
    'STAND.Input_Placeholder_Search' : 'Suche',
    'STAND.Filter_Params_Exhibitor' : 'Aussteller: { exhibitorName }',
    'STAND.Card_Hero_Hall' : 'Halle',
    'STAND.Card_Hero_Block' : 'Block',
    'STAND.Card_Hero_Stand' : 'Stand',
    'STAND.Card_Hero_DeliveryTime' : 'Deadline',
    'STAND.Modal_Title_StandPlan' : 'Standzeichnung',
    'STAND.Modal_Button_StandPlan' : 'Standzeichnung',
    'STAND.Card_Button_Details' : 'Details { icon }',
    'STAND.Modal_Title_StandPhoto' : 'Standfoto',
    'STAND.Modal_Button_StandPhoto' : 'Standfoto',
    'STAND.Modal_Button_SavePhoto' : 'Foto speichern',
    'STAND.Modal_Button_AddPhoto' : 'Foto hinzufügen',
    'STAND.Block_Title_Overview' : 'Übersicht',
    'STAND.Block_Title_Materials' : 'Anhänge',
    'STAND.Block_Title_Crafts' : 'Gewerke',
    'STAND.Block_ItemTitle_Hall' : 'Halle',
    'STAND.Block_ItemTitle_Block' : 'Block',
    'STAND.Block_ItemTitle_Stand' : 'Stand',
    'STAND.Block_ItemTitle_ProaufNumber' : 'PROAUF Nummer',
    'STAND.Block_ItemTitle_Jobs' : 'Jobs',
    'STAND.Block_Item_JobCountInProgress' : '{ jobCount } in Bearbeitung',
    'STAND.Block_Item_JobCountOpen' : '{ jobCount } offen',
    'STAND.Block_Item_JobCountFinished' : '{ jobCount } Fertig',
    'STAND.Block_Button_ViewStandJobs' : 'Jobs Anzeigen',
    'STAND.Modal_Title_StandGraphic' : 'Standgrafik',
    'STAND.Modal_Button_StandGraphic' : 'Standgrafik',
    'STAND.Modal_Title_ExtraInfo' : 'Zusatzinfo',
    'STAND.Modal_Button_ExtraInfo' : 'Zusatzinfo',
    'STAND.Button_Text_ViewKeys' : 'Schlüssel',
    'STAND.Button_Text_ViewLogs' : 'Logs anzeigen',
    'STAND.Modal_Button_AddKey' : 'Schlüssel hinzufügen',
    'STAND.Modal_Title_AddKey' : 'Schlüssel hinzufügen',
    'STAND.Modal_Title_EditKey' : 'Schlüssel bearbeiten',
    'STAND.Modal_Button_UpdateKey' : 'Schlüssel aktualisieren',
    'STAND.Filter_Params_Stand' : 'Stand { withFilter }',
    'STAND.Button_Text_DeleteKey' : 'Löschen',
    'STAND.Text_ModalDelete_Question' : 'Schlüssel { keyName } wirklich löschen?',
    'JOB.Button_Text_ViewPhoto' : 'Foto ansehen',
    'OVERVIEW_TYPES.Label_InTotal' : 'gesamt',
    'OVERVIEW_TYPES.Label_Finished' : 'fertig',
    'OVERVIEW_TYPES.Label_Open' : 'offen',
    'OVERVIEW_TYPES.Label_Claims'  : 'reklamiert',
    'OVERVIEW_TYPES.Label_LastMinute' : 'last min',
    'OVERVIEW_TYPES.Label_Overdue' : 'überfällig',
    'FURNISHING.TabName_Storage'   : 'LM Artikel',
    'FURNISHING.TabName_Refund'    : 'Möbel Returen',
    'FURNISHING.TabName_OrderLog'  : 'Änderungs- Log',
    'FURNISHING.Modal_Title_ReturnFurnishing' : 'LM Ausstattungsmengen',
    'FURNISHING.Modal_Title_FurnishingStore' : 'Verfügbare Ausstattung',
    'FURNISHING.Button_Text_Return' : 'Zurückkehren',
    'FURNISHING.Button_Text_ChooseFurnishing' : 'Ausstattung auswählen',
    'FURNISHING.Modal_Title_Photo' : "Foto von Möbeln",
    'FURNISHING.Order_Type_Refund' : "Zurückkehren",
    'FURNISHING.Order_Type_Lost'   : "Verloren",
    'FURNISHING.Order_Type_Correction' : "Korrektur",
    'FURNISHING.Order_Type_Order'  : "Befehl",
};

export default deutsch;