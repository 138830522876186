import React from 'react';
import Icon from '@ant-design/icons';

import { Loader } from "components/request-result";

const LogisticsSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M38.4,32.2c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2
		C41.7,33.7,40.2,32.2,38.4,32.2z"/>
            <path d="M45.4,26l-6.8-8.3c-0.4-0.5-1-0.8-1.6-0.8h-4.3c-0.8,0-1.5,0.7-1.5,1.5v16c0,0.7,0.6,1.3,1.3,1.3h0.8
		c0-0.1,0-0.2,0-0.2c0-2.8,2.3-5.1,5.1-5.1c2.8,0,5.1,2.3,5.1,5.1c0,0.1,0,0.2,0,0.2h1.2c0.7,0,1.2-0.5,1.2-1.2v-7.2
		C45.9,26.8,45.7,26.3,45.4,26z M43.1,27.1H38c-0.3,0-0.6-0.3-0.6-0.6v-6.6c0-0.3,0.4-0.5,0.7-0.2c1.3,1.6,4.1,5.1,5.5,6.7
		C43.8,26.6,43.5,27.1,43.1,27.1z"/>
            <path d="M28.4,11.2H3c-0.6,0-1.2,0.5-1.2,1.2v22c0,0.7,0.5,1.2,1.2,1.2h4.1c0,0,0-0.1,0-0.1c0-2.8,2.3-5.1,5-5.1
		s5,2.3,5,5.1c0,0,0,0.1,0,0.1h11.3c0.6,0,1.2-0.5,1.2-1.2v-22C29.6,11.7,29,11.2,28.4,11.2z M16.4,23.7H6.2c-0.5,0-1-0.5-1-1
		s0.4-1,1-1h10.1c0.5,0,1,0.5,1,1S16.9,23.7,16.4,23.7z M18.8,18.7H9.8c-0.5,0-1-0.5-1-1s0.4-1,1-1h8.9c0.5,0,1,0.5,1,1
		S19.3,18.7,18.8,18.7z"/>
            <path d="M12.2,32.2c-1.8,0-3.2,1.5-3.2,3.2c0,1.8,1.5,3.2,3.2,3.2s3.2-1.5,3.2-3.2C15.4,33.7,14,32.2,12.2,32.2z" />
        </g>
    </svg>
);

const FloorSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M28.4,21.7c0,0.1-0.1,0.1-0.1,0.2l-0.6,0.6L12.2,38h18.1l16.6-16.3C46.9,21.7,28.4,21.7,28.4,21.7z" />
            <path d="M13,25.2c1.6,1.6,2.5,3.8,2.5,6c0,0.2,0,0.4,0,0.6l11.1-11.1c2.4-2.5,2.3-6.5-0.1-9c-1.2-1.2-2.8-1.9-4.5-1.9
		s-3.3,0.6-4.5,1.8l-0.1,0.2l-11,11C8.8,22.6,11.2,23.4,13,25.2z"/>
            <path d="M11.4,26.8C10.2,25.6,8.6,25,7,25s-3.1,0.6-4.3,1.8s-1.8,2.7-1.8,4.4c0,1.6,0.6,3.2,1.8,4.4
		c2.4,2.4,6.3,2.4,8.7,0c1.2-1.2,1.8-2.7,1.8-4.4C13.2,29.5,12.6,28,11.4,26.8z"/>
        </g>
    </svg>
);

const AssemblySvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M34.3,31.6c0.5-0.6,1.3-0.6,1.8-0.2l4.2,3.5c1-2.6,0.3-5.6-1.9-7.5c-2-1.7-4.8-1.9-7-0.9l-4.4-3.7l6-6
		c0.6,0.3,1.3,0.3,1.8-0.2l1.1-1.6l1.9-2.8c0.6-0.6,0.6-1.5,0-2.1l-0.2-0.2c-0.6-0.6-1.5-0.6-2.1,0l-4.5,3c-0.5,0.5-0.5,1.2-0.2,1.8
		l-6.2,6.2l-4-3.3c0.6-2.4-0.1-5-2.2-6.7c-2.3-1.9-5.4-2-7.7-0.5l4.2,3.5c0.6,0.5,0.6,1.3,0.2,1.9l-0.9,1.1
		c-0.5,0.6-1.3,0.6-1.9,0.2l-4.2-3.5c-1,2.6-0.3,5.6,1.9,7.5c1.9,1.6,4.6,1.9,6.7,1l3.7,3.1l-1.8,1.8l-1.6-1.6
		c-0.5-0.5-1.2-0.5-1.7,0l-0.6,0.6c-0.5,0.5-0.5,1.2,0,1.7l0.4,0.4c0,0,0,0-0.1,0l-6.6,6.6c-0.9,0.9-0.9,2.5,0,3.4L9.8,39
		c0.9,0.9,2.5,0.9,3.4,0l6.6-6.6c0,0,0,0,0-0.1l0.4,0.4c0.5,0.5,1.2,0.5,1.7,0l0.6-0.6c0.5-0.5,0.5-1.2,0-1.7l-1.6-1.6l2-2l4.9,4.1
		c-0.5,2.3,0.3,4.8,2.2,6.4c2.3,1.9,5.4,2,7.7,0.5l-4.2-3.5c-0.6-0.5-0.6-1.3-0.2-1.8L34.3,31.6z"/>
        </g>
    </svg>
);

const ElectricsSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M23.8,6C14,6,6,14,6,23.8s8,17.8,17.8,17.8c0.9,0,1.7-0.7,1.7-1.7v-6.4c3.8-0.3,6.7-3.4,6.7-7.2v-5.2
	c0-0.8-0.7-1.5-1.5-1.5h-1.4v-3.8c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7v3.8h-4.1v-3.8c0-0.9-0.7-1.7-1.7-1.7
	c-0.9,0-1.7,0.7-1.7,1.7v3.8h-1.2c-0.8,0-1.5,0.7-1.5,1.5v5.2c0,3.6,2.7,6.6,6.2,7.1v4.8c-7-0.8-12.6-6.9-12.6-14.4
	c0-8,6.5-14.5,14.5-14.5s14.5,6.5,14.5,14.5c0,6-3.8,11.5-9.4,13.6c-0.9,0.3-1.3,1.3-1,2.1c0.3,0.9,1.3,1.3,2.1,1
	c6.9-2.6,11.5-9.3,11.5-16.7C41.6,14,33.6,6,23.8,6z"/>
    </svg>
);

const GraphicsSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M35.5,7.5H13.1c-3.1,0-5.7,2.5-5.7,5.7v22.4c0,3.1,2.5,5.7,5.7,5.7h22.4c3.1,0,5.7-2.5,5.7-5.7V13.2
	C41.1,10,38.6,7.5,35.5,7.5z M28,16.1c1.3,0,2.3,1,2.3,2.3c0,1.3-1,2.3-2.3,2.3s-2.3-1-2.3-2.3C25.7,17.2,26.7,16.1,28,16.1z
	 M37.5,35.5c0,1.1-0.9,2-2,2H13.1c-1.1,0-2-0.9-2-2v-4.9l7-6.8c0.4-0.3,1-0.3,1.5,0l6.5,7.5c0.3,0.2,0.7,0.2,1,0l10.3-9.4V35.5z"/>
    </svg>
);

const CollectGraphicsFilledSvg = () => (

	<svg fill="currentColor" width="1.1em" height="1.1em" viewBox="0 0 48 48" strokeWidth="1">
		<path d="M25.05,9.84h12.38l-3.15,1.65c-.61.32-.85,1.08-.53,1.69.22.43.66.67,1.11.67.2,0,.39-.05.58-.14l7.63-4.01c.41-.22.67-.64.67-1.11s-.26-.89-.67-1.11l-7.63-4.01c-.61-.32-1.37-.09-1.69.52-.32.61-.09,1.37.53,1.69l3.15,1.65h-12.38c-.69,0-1.25.56-1.25,1.25s.56,1.25,1.25,1.25Z" />
		<path d="M43.11,31.57l-1.93-.88,2.02-1.04c1.37-.71,1.32-2.68-.08-3.31l-2.25-1.02-3.4,1.75,2.23,1.01-2,1.03-3.4,1.75-9.76,5.04c-.09.05-.18.07-.28.07-.09,0-.18-.02-.26-.06l-9.35-4.44-3.43-1.63-1.9-.9,2.05-1.04,6.41,3.04,4.73,2.25c.25.12.51.21.78.26.09.02.18.02.26.03.17.02.34.06.51.06,0,0,.02,0,.03,0,.19,0,.37-.04.55-.07.09-.02.19-.01.28-.04.27-.07.54-.16.8-.3l17.28-8.92c1.2-.62,1.31-2.2.38-3.02-.13-.12-.29-.22-.46-.3l-17.04-7.72c-.24-.11-.5-.19-.75-.24-.09-.02-.17-.02-.26-.03-.16-.02-.32-.05-.48-.05-.01,0-.02,0-.04,0-.18,0-.36.03-.54.06-.09.01-.18.01-.27.04-.26.06-.52.16-.77.28,0,0,0,0-.01,0l-11.36,5.74-5.74,2.9c-.17.09-.32.19-.45.31-.64.6-.73,1.58-.29,2.3.18.29.44.53.78.7l2.25,1.07-2.09,1.06c-1.37.69-1.34,2.65.04,3.31l1.94.92-1.98,1c-1.37.69-1.34,2.65.04,3.31l16.82,7.99c.49.23,1.02.35,1.55.35.57,0,1.14-.13,1.66-.4l17.28-8.92c1.37-.71,1.32-2.68-.08-3.31ZM31.39,19.44c1.44,0,2.61.72,2.61,1.62s-1.17,1.62-2.61,1.62-2.61-.72-2.61-1.62,1.17-1.62,2.61-1.62ZM14.94,20.56l7.86.37.28,4.68,9.84.43-8.58,4.43c-.09.05-.18.07-.28.07-.09,0-.18-.02-.26-.06l-14.69-6.97,5.83-2.95ZM24.54,41.13c-.09.04-.18.07-.28.07-.09,0-.18-.02-.26-.06l-14.69-6.97,1.95-.98,11.45,5.44c.49.23,1.02.35,1.55.35.57,0,1.14-.13,1.66-.4l11.87-6.13,1.91.87-15.15,7.82Z" />
	</svg>
);

const FurnitureSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M43.1,18.1h-3h-1.2l0,0c-1.3,0-2.3,1-2.3,2.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v10.5H11.6V20.6c0,0,0-0.1,0-0.1
		c0,0,0-0.1,0-0.1c0-0.8-0.4-1.5-1-1.9c-0.4-0.2-0.8-0.4-1.3-0.4l0,0H8.1h-3c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3h0.6V34
		c0,1.3,1.1,2.4,2.4,2.4h0.6v2.4c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-2.4h25.3v2.4c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-2.4
		c0.1,0,0.1,0,0.2,0h1c1.3,0,2.4-1.1,2.4-2.4V22.6h0.6c1.3,0,2.3-1,2.3-2.3C45.4,19.1,44.3,18.1,43.1,18.1z"/>
            <path d="M13.5,20.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v3.1h21.2v-3.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
		c0-2.3,1.9-4.2,4.2-4.2h1.2v-3.4c0-2.3-1.9-4.1-4.1-4.1H12.2c-2.3,0-4.1,1.9-4.1,4.1v3.4h1.2C11.6,16.1,13.5,18,13.5,20.4z"/>
            <rect x="13.5" y="25.7" width="21.2" height="3.5" />
        </g>
    </svg>
);

const MultimediaSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M11.3,31.3h25.3c2,0,3.5-1.6,3.5-3.5V12.3c0-2-1.6-3.5-3.5-3.5H11.3c-2,0-3.5,1.6-3.5,3.5v15.4
		C7.7,29.7,9.3,31.3,11.3,31.3z M19.4,19.9V15c0-1,1.1-1.7,2-1.2l4.3,2.5l4.3,2.5c0.9,0.5,0.9,1.8,0,2.3l-4.3,2.5L21.4,26
		c-0.9,0.5-2-0.1-2-1.2V19.9z"/>
            <path d="M38.5,35.5H18.9c-0.6-1.2-1.9-2-3.3-2c-1.4,0-2.7,0.8-3.3,2H9.5c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h2.8
		c0.6,1.1,1.9,1.9,3.3,1.9s2.6-0.8,3.3-1.9h19.6c1,0,1.8-0.8,1.8-1.8S39.5,35.5,38.5,35.5z"/>
        </g>
    </svg>
);

const CleaningSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M6.5,6c0,0-0.4,5-4.8,5c0,0,4.7,0.7,4.7,5c0,0,0.3-5.2,4.5-5.2C10.8,10.8,6.6,10.5,6.5,6z" />
            <path d="M43.3,30.6c-0.1,0-2.7-1.2-3.6-6.4c-0.2-1.4-0.4-2.8-0.5-4.3c-0.5-5-1.1-10.1-4.6-13.8
		c-0.4-0.4-0.9-0.4-1.3-0.1c0,0-3.8,2.9-10.2,3c-6.3,0.1-10.7,2.1-14.4,6.6c-0.3,0.3-0.3,0.8-0.1,1.2c0,0,1.9,2.9,2.4,9
		c0.5,6.4,3.3,12.8,6.7,15.2c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0,0,1.9-1.4,4.4-2.8c0.2,1.1,0.5,2.3,0.7,3.4
		c0.1,0.6,0.4,1.1,0.9,1.4c0.3,0.2,0.7,0.3,1.1,0.3c0.2,0,0.4,0,0.6-0.1l12-3.5c0.6-0.2,1-0.6,1.3-1.1c0.2-0.5,0.3-1.1,0-1.7
		c-0.4-0.9-0.8-1.8-1.1-2.6c1.7-0.4,3.3-1,4.8-1.9c0.3-0.2,0.5-0.6,0.5-0.9C43.8,31.1,43.6,30.8,43.3,30.6z M25.9,41.3
		c0,0-0.7-3.7-1.2-5.7c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2
		c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.5-1-1.3-1.5-1.9l-5.2-5.2c-0.6-0.6-0.6-1.6,0-2.2c0.2-0.2,0.3-0.3,0.5-0.3
		c0,0,0,0,0,0c0.2-0.1,0.4-0.1,0.6-0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1c0,0,0,0,0,0
		c0.2,0.1,0.4,0.2,0.5,0.3l2,2c0,0,0,0,0-0.1l-3-9.7c-0.2-0.7,0-1.4,0.7-1.7c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.1,0,0.1,0
		c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.2,0.4l2.6,8.4
		c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4
		l-2.8-9.5c-0.2-0.7,0.1-1.4,0.8-1.6c0.1,0,0.2,0,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.4,0.1c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.2
		c0,0,0,0,0.1,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0,0,0.1c0.1,0.1,0.1,0.2,0.2,0.4l2.7,9.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0
		c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.3,0.3-0.5l-2.2-7.4c-0.2-0.7,0.1-1.3,0.8-1.5
		c0.2,0,0.3-0.1,0.5,0c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1
		c0.1,0.1,0.2,0.3,0.2,0.4l2.2,7.6c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
		c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.3-0.1,0.3-0.3,0.2-0.5l-1.6-5.4l-0.1-0.2c-0.2-0.8,0.2-1.3,0.7-1.5c0.1,0,0.2,0,0.4,0
		c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.2,0.5
		l2,8.2c0,0,0,0,0,0.1c0.4,1.5,1.2,4.8,1.4,5.6c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
		c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3
		c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0,0.1,0.1,0.2c0.1,0.2,0.1,0.4,0.2,0.5c0,0,0,0.1,0,0.1
		c0.8,1.8,1.6,3.6,1.6,3.6L25.9,41.3z"/>
        </g>
    </svg>
);

const AdditionalSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M32.2,22.6h-5.8v-5.8c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8v5.8h-5.8c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h5.8v5.8
		c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8v-5.8h5.8c1,0,1.8-0.8,1.8-1.8S33.2,22.6,32.2,22.6z"/>
            <path d="M24.5,6.4c-9.8,0-17.8,8-17.8,17.8S14.8,42,24.5,42s17.8-8,17.8-17.8S34.3,6.4,24.5,6.4z M24.5,38.5
		c-7.9,0-14.3-6.4-14.3-14.3S16.7,9.9,24.5,9.9s14.3,6.4,14.3,14.3S32.4,38.5,24.5,38.5z"/>
        </g>
    </svg>
);

const MPControlSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M39.3,22.7c-1,0-1.8,0.8-1.8,1.8c0,7.6-6.2,13.7-13.7,13.7s-13.7-6.2-13.7-13.7s6.2-13.7,13.7-13.7
		c3.2,0,6.3,1.1,8.7,3.1c0.8,0.6,1.9,0.5,2.5-0.2c0.6-0.8,0.5-1.9-0.2-2.5c-3.1-2.6-7-4-11-4C14.2,7.2,6.4,15,6.4,24.5
		s7.8,17.3,17.3,17.3s17.3-7.8,17.3-17.3C41.1,23.5,40.3,22.7,39.3,22.7z"/>
            <path  d="M43.5,11.2c-0.7-0.7-1.9-0.7-2.6,0L25,27.2L17.9,20c-0.7-0.7-1.9-0.7-2.6,0s-0.7,1.9,0,2.6l8.4,8.4
		c0.4,0.4,0.8,0.5,1.3,0.5s0.9-0.2,1.3-0.5l17.2-17.2C44.2,13.1,44.2,11.9,43.5,11.2z"/>
        </g>
    </svg>
);

const ProtocolSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M34.1,7.7h-2.6c-0.3-0.9-1.1-1.6-2.1-1.6H18.8c-1,0-1.8,0.7-2.1,1.6H14c-2.2,0-4,1.8-4,4v25.5c0,2.2,1.8,4,4,4
		h20.1c2.2,0,4-1.8,4-4V11.7C38.2,9.5,36.3,7.7,34.1,7.7z M34.5,37.3c0,0.2-0.2,0.4-0.4,0.4H14c-0.2,0-0.4-0.2-0.4-0.4V11.7
		c0-0.2,0.2-0.4,0.4-0.4h2.7c0.3,0.9,1.1,1.5,2.1,1.5h10.6c1,0,1.8-0.6,2.1-1.5h2.7c0.2,0,0.4,0.2,0.4,0.4V37.3z"/>
            <path d="M30.3,16.7H17.9c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h12.4c1,0,1.8-0.8,1.8-1.8
		C32.1,17.5,31.3,16.7,30.3,16.7z"/>
            <path d="M30.3,22.3H17.9c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h12.4c1,0,1.8-0.8,1.8-1.8S31.3,22.3,30.3,22.3z" />
            <path d="M30.3,27.9H17.9c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h12.4c1,0,1.8-0.8,1.8-1.8
		C32.1,28.7,31.3,27.9,30.3,27.9z"/>
        </g>
    </svg>
);


const ServiceSvg = () => (

    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <g>
            <path d="M3.8,33.1l8.6-8.2c0,0,2.3-2.3,5.1-2.3s4.9,1.2,4.9,1.2h7.8c0,0,2.3-0.1,2.3,2c0,1.9-2.2,2-2.2,2l-5.1,0.1
		c0,0-0.9,0-0.9,0.9c0,0.5,0.4,0.8,1,0.8c0.4,0,5.8,0,5.8,0s0.7,0,1.5-0.5c0.4-0.2,0.8-0.6,1.3-1.1c1.7-1.8,5.9-6.6,5.9-6.6
		s1.7-2.2,3.5-1c1.6,1.1,0.4,2.9,0.2,3.2c-0.4,0.6-7.2,9.3-7.2,9.3S34,36.1,30,36.1s-13.6,0-13.6,0l-4.9,4.5L3.8,33.1z"/>
            <path d="M32.9,13l-1.2-0.2c-0.1-0.5-0.3-1-0.6-1.5l0.7-1c0.2-0.2,0.2-0.6,0-0.8l-0.8-0.8c-0.2-0.2-0.6-0.2-0.8,0l-1,0.7
		c-0.5-0.3-1-0.5-1.5-0.6l-0.2-1.2c0-0.3-0.3-0.6-0.6-0.6h-1.1c-0.3,0-0.6,0.3-0.6,0.6l-0.2,1.2c-0.5,0.1-1,0.3-1.5,0.6l-1-0.7
		c-0.2-0.2-0.6-0.2-0.8,0l-0.8,0.8c-0.2,0.2-0.2,0.6,0,0.8l0.7,1c-0.3,0.5-0.5,1-0.6,1.5L19.4,13c-0.3,0-0.6,0.3-0.6,0.6v1.1
		c0,0.3,0.3,0.6,0.6,0.6l1.2,0.2c0.1,0.5,0.3,1,0.6,1.5l-0.7,1c-0.2,0.2-0.2,0.6,0,0.8l0.8,0.8c0.2,0.2,0.6,0.2,0.8,0l1-0.7
		c0.5,0.3,1,0.5,1.5,0.6l0.2,1.2c0,0.3,0.3,0.6,0.6,0.6h1.1c0.3,0,0.6-0.3,0.6-0.6l0.2-1.2c0.5-0.1,1-0.3,1.5-0.6l1,0.7
		c0.2,0.2,0.6,0.2,0.8,0l0.8-0.8c0.2-0.2,0.2-0.6,0-0.8l-0.7-1c0.3-0.5,0.5-1,0.6-1.5l1.2-0.2c0.3,0,0.6-0.3,0.6-0.6v-1.1
		C33.5,13.3,33.2,13,32.9,13z M26.1,17c-1.6,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8S27.7,17,26.1,17z"/>
        </g>
    </svg>
);

const DamageSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M38,6.2H10c-2.3,0-4.2,1.9-4.2,4.2v27.3c0,2.3,1.9,4.2,4.2,4.2h28c2.3,0,4.2-1.9,4.2-4.2V10.4C42.2,8.1,40.4,6.2,38,6.2z
	        M10,9.7h15.6l-0.2,6.6L16,19l2.8,8.2l-9.6,2.5V10.4C9.3,10,9.6,9.7,10,9.7z M38.7,37.7c0,0.4-0.3,0.7-0.7,0.7H10
	        c-0.4,0-0.7-0.3-0.7-0.7v-4.5l14.1-3.7l-2.9-8.3l8.3-2.4l0.2-9.3H38c0.4,0,0.7,0.3,0.7,0.7V37.7z"
        />
    </svg>
);


const CraftIcon = props => {

    let svg;

    switch (props.type){
        case "logistics":           svg = LogisticsSvg;               break;
        case "floor":               svg = FloorSvg;                   break;
        case "assembly":            svg = AssemblySvg;                break;
        case "electrics":           svg = ElectricsSvg;               break;
        case "graphic":             svg = GraphicsSvg;                break;
        case "collect_graphics":    svg = CollectGraphicsFilledSvg;   break;
        case "furniture":           svg = FurnitureSvg;               break;
        case "multimedia":          svg = MultimediaSvg;              break;
        case "cleaning":            svg = CleaningSvg;                break;
        case "additional":          svg = AdditionalSvg;              break;
        case "mp_control":          svg = MPControlSvg;               break;
        case "protocol":            svg = ProtocolSvg;                break;
        case "service":             svg = ServiceSvg;                 break;
        case "damage":              svg = DamageSvg;                  break;
        default:                    svg = LogisticsSvg;
    }

    if(props.status === "loading"){
        return <Loader />;
    }

    return <Icon component={ svg } className={ props.className } />;

};


export default CraftIcon;