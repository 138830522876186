import React from "react";
import { useMutation } from "@apollo/client";
import { Button, message } from "antd";

import { CRAFT_CHANGE_STATUS } from "graphql/mutation/craft-gql";

import CraftGrids from "../grids";
import { useNotification } from "components/use-hooks";
import Localize, { localizeIt } from "components/service/localize";
import { Icons } from "components/layout";


const CraftChangeStatusBtn = ({ id, status, type, justButton = false, callback = () => { } }) => {

    const { nError, nSuccess } = useNotification();
    const [ craftChangeStatus, { loading } ] = useMutation(CRAFT_CHANGE_STATUS,
        {
            update(cache, { data }) {

                const {
                    craftUpdateStatus: {
                        label,
                        message
                    }
                } = data;

                callback();

                nSuccess(label, message);
            }
        }
    );

    const Wrap = ({ children, ...props }) => {
        if (justButton)
        {
            return (
                <Button
                    { ...props }
                    type="primary"
                >
                    { children }
                </Button>
            )
        }

        return (
            <button { ...props }>{ children }</button>
        )
    }

    const isCollectGraphics = type === "collect_graphics";

    let nextStatus;
    if (isCollectGraphics)
    {
        if (status === "done")
        {
            nextStatus = "open";
        } else if (status === "open")
        {
            nextStatus = "collected";
        } else
        {
            nextStatus = "open";
        }
    } else
    {
        nextStatus = (status !== "completed" ? "completed" : "in_progress");
    }

    // Define the button class
    const buttonClassName = isCollectGraphics
        ? `craft-change-status-btn status ${ status === "open" ? "done" : "open" } collect-graphics-type`
        : `craft-change-status-btn status ${ status !== "completed" ? "completed" : "in-progress" }`;

    const handleClick = () => {
        if (loading) return;

        if (isCollectGraphics && status === "done")
        {
            message.warning(localizeIt("CRAFT.Status_Warning_Cannot_Change_Done"));
            return;
        }

        craftChangeStatus({
            variables: {
                id,
                status: nextStatus
            }
        }).catch((error) => nError(error));
    };

    return (
        <Wrap
            onClick={ handleClick }
            className={ buttonClassName }
            data-type={ type }
        >
            { !justButton ?
                <>
                    <CraftGrids.Single
                        extraClass={ isCollectGraphics
                            ? (status === "open" ? "done" : "open")
                            : (status !== "completed" ? "completed" : "in_progress") }
                        type={ type }
                        loading={ loading }
                    />
                    { isCollectGraphics ?
                        (status === "open" ?
                            <Localize>CRAFT.Button_Text_MarkDone</Localize> :
                            <Localize>CRAFT.Button_Text_MarkOpen</Localize>)
                        :
                        (status !== "completed" ?
                            <Localize>CRAFT.Button_Text_MarkDone</Localize> :
                            <Localize>CRAFT.Button_Text_MarkInProgress</Localize>)
                    }
                </> :
                <>
                    { isCollectGraphics ?
                        (status === "open" ?
                            <>
                                <Icons.Control loading={ loading } className="collect-graphics-icon" />
                                <Localize>CRAFT.Status_Text_Open</Localize>
                            </> :
                            <>
                                <Icons.Check type="double" loading={ loading } className="collect-graphics-icon" />
                                <Localize>CRAFT.Status_Text_Collected</Localize>
                            </>
                        ) :
                        (status !== "completed" ?
                            <>
                                <Icons.Control loading={ loading } />
                                <Localize>CRAFT.Button_ChangeStatus_Done</Localize>
                            </> :
                            <>
                                <Icons.InProgress loading={ loading } />
                                <Localize>CRAFT.Button_ChangeStatus_InProgress</Localize>
                            </>
                        )
                    }
                </>
            }
        </Wrap>
    )
}

export default CraftChangeStatusBtn