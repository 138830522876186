import MorePage from "./more-page";
import ViewDamagesPage from "./view-damages-page";
import CraftsOverviewPage from "./crafts-overview-page";
import HallsOverviewPage from "./halls-overview-page";
import DocumentsPage from "./documents-page";
import HelpImprintPage from "./help-imprint-page";
import FurnishingPage from "./furnishing-page";
import CollectGraphicsPage from "./collect-graphics-page";


const MorePages = {
    Single         : MorePage,
    ViewDamages    : ViewDamagesPage,
    CraftsOverview : CraftsOverviewPage,
    HallsOverview  : HallsOverviewPage,
    Documents      : DocumentsPage,
    Furnishing     : FurnishingPage,
    HelpImprint    : HelpImprintPage,
    CollectGraphics: CollectGraphicsPage
}

export default MorePages;