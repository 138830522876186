import CollectGraphicsPreviewBlock from "./collect-graphics-preview-block";
import CollectGraphicsInfoBlock from "./collect-graphics-info-block";

import "./collect-graphics-blocks.scss";

const CollectGraphicsBlocks = {
    Preview: CollectGraphicsPreviewBlock,
    Info: CollectGraphicsInfoBlock
};

export default CollectGraphicsBlocks;