const english = {
    'CRAFT.Type_Logistics' : 'Logistics { number }',
    'CRAFT.Type_Floor' : 'Floor { number }',
    'CRAFT.Type_Assembly' : 'Assembly { number }',
    'CRAFT.Type_Electrics' : 'Electrics { number }',
    'CRAFT.Type_Graphic' : 'Graphic { number }',
    'CRAFT.Type_Furniture' : 'Furniture { number }',
    'CRAFT.Type_Multimedia' : 'Multimedia { number }',
    'CRAFT.Type_Cleaning' : 'Cleaning { number }',
    'CRAFT.Type_Additional' : 'Additional { number }',
    'CRAFT.Type_MPControl' : 'MP Control',
    'CRAFT.Type_Protocol' : 'Protocol',
    'CRAFT.Type_Service' : 'Service { number }',
    'CRAFT.Type_CollectGraphics' : 'Collect Graphics',
    'CRAFT.Status_Text_InProgress' : 'In progress',
    'CRAFT.Status_Text_Disabled' : 'Disabled',
    'CRAFT.Status_Text_Completed' : 'Completed',
    'CRAFT.Status_Text_Overdue' : 'Overdue',
    'CRAFT.Status_Text_Done' : 'Collected',
    'CRAFT.Status_Text_Open' : 'Open',
    'CRAFT.Status_Text_Collected': 'Collected',
    'CRAFT.Status_Warning_Cannot_Change_Done': 'Cannot change status: the graphic has already been processed in the admin panel',
    'CRAFT.Button_ViewAll' : 'View all',
    'CRAFT.Button_Text_Print' : 'Print',
    'CRAFT.Button_Text_Stand' : 'Stand',
    'CRAFT.Button_Text_MarkOpen' : 'Mark craft open',
    'CRAFT.Button_Text_MarkDone' : 'Mark craft done',
    'CRAFT.Button_Text_MarkInProgress' : 'Mark craft in progress',
    'CRAFT.Button_ChangeStatus_InProgress' : 'In progress',
    'CRAFT.Button_ChangeStatus_Done': 'Done',
    'CRAFT.Button_Text_ShelfNumber' : 'Shelf No.',
    'CRAFT.Filter_Params_CraftType' : 'Craft: { withFilter }',
    'EXHIBITION.Select_Instruction' : 'Please select an exhibition { br } from the list below',
    'EXHIBITION.Select_Label' : 'CHOSEN EXHIBITION',
    'EXHIBITION.Modal_Title' : 'CHOOSE EXHIBITION',
    'EXHIBITION.Timer_State_Underway' : 'UNDERWAY',
    'EXHIBITION.Timer_State_Ended' : 'ENDED',
    'EXHIBITION.Title_CraftTotal' : 'Total crafts overview',
    'EXHIBITION.Statistic_Status_InTotal' : 'in total',
    'EXHIBITION.Statistic_Status_InProgress' : 'in progress',
    'EXHIBITION.Statistic_Status_Done' : 'done',
    'EXHIBITION.Statistic_Status_Overdue' : 'overdue',
    'EXHIBITION.Statistic_Status_NotApproved' : 'not approved',
    'EXHIBITION.Statistic_Type_Stands' : 'Stands',
    'EXHIBITION.Statistic_Type_Claims' : 'mp Claims',
    'EXHIBITION.Statistic_Type_CustomerClaims' : 'Exhibitor claims',
    'EXHIBITION.Statistic_Type_LastMinute' : 'Last minute',
    'EXHIBITION.Statistic_Type_MpControl' : 'mp Control',
    'EXHIBITION.Statistic_Type_Damages' : 'Damages',
    'EXHIBITION.Statistic_Type_Protocol' : 'Protocol',
    'FORM.Textarea_Placeholder_JobDescription' : 'Job description*',
    'FORM.Button_Text_AddJob' : 'Add job',
    'FORM.Button_Text_SaveJob' : 'Save job',
    'FORM.Button_AddJobPhoto' : 'Add photo',
    'FORM_RULES.Required_Login' : 'Please input your Login',
    'FORM_RULES.Required_Password' : 'Please input your Password',
    'FORM_RULES.Required_KeyDescription' : 'Please input key description',
    'FORM_RULES.Required_KeyNumber' : 'Please input key number',
    'FORM_RULES.Required_JobDescription' : 'Please add job description',
    'FORM_RULES.Required_Quantity' : 'Please input quantity',
    'FORM_RULES.Min_Quantity' : 'Quantity must be a number not less then one',
    'FORMS.Input_Label_Login' : 'login',
    'FORMS.Input_Label_Password' : 'password',
    'FORMS.Button_Text_Login' : 'Login',
    'FORMS.Input_Label_Description' : 'Description',
    'FORMS.Input_Label_KeyNumber' : 'Key Number',
    'FORMS.Input_Label_Hall' : 'Hall',
    'FORMS.Input_Label_Stands' : 'Stands',
    'FORMS.Button_Text_ApplyFilters' : 'Apply',
    'FORMS.Button_Text_ResetFilters' : 'Reset Filters',
    'FORMS.Select_DefaultValue_All' : 'All',
    'FORMS.Select_StandTypeValue_CraftInProgress' : 'With crafts in progress',
    'FORMS.Select_StandTypeValue_CraftCompleted' : 'With completed crafts',
    'FORMS.Select_StandTypeValue_ControlInProgress' : 'With control crafts in progress',
    'FORMS.Select_StandTypeValue_ProtocolInProgress' : 'With protocol crafts in progress',
    'FORMS.Select_StandTypeValue_OverdueCrafts' : 'With overdue crafts',
    'FORMS.Select_StandTypeValue_AdditionalJobs' : 'With additional jobs',
    'FORMS.Button_Text_ApplySort' : 'Apply',
    'FORMS.Input_Label_CraftType' : 'Craft Type',
    'FORMS.Input_Label_JobType'   : 'Type',
    'FORMS.Input_Label_JobFlag'   : 'Flag',
    'FORMS.Input_Label_JobStatus' : 'Status',
    'FORMS.Input_Label_CraftStatus' : 'Craft Status',
    'FORMS.Input_Label_Subcontractor' : 'Subcontractor',
    'FORMS.Select_JobStatusValue_JobsOpen' : 'Open Jobs',
    'FORMS.Select_JobStatusValue_JobsInProgress' : 'Jobs in progress',
    'FORMS.Select_JobStatusValue_FinishedJobs' : 'Finished Jobs',
    'FORMS.Select_JobFlag_WithoutFlag' : 'Without flag',
    'FORMS.Select_JobFlag_WithFlag' : 'With flag',
    'FORMS.Input_Label_FurnishingName' : 'Furnishing name',
    'FORMS.Input_Label_FurnishingReturnQuantity' : 'Quantity',
    'FORMS.Input_Label_Quantity' : 'Quantity',
    'FORMS.Input_Label_CurrentQuantity' : 'Current quantity',
    'FORMS.Input_Label_NewQuantity' : 'New quantity',
    'FORMS.RadioInput_Text_Description' : 'Description',
    'FORMS.RadioInput_Text_Furnishing' : 'Furnishing',
    'FORMS.RadioInput_Text_FurnishingClaim' : 'Furnishing',
    'FORMS.Input_Label_OrderType'   : 'Type',
    'FORMS.Select_FurnishingOrder_Type_Return' : "Article return",
    'FORMS.Select_FurnishingOrder_Type_Lost' : "Lost",
    'FORMS.Select_FurnishingOrder_Type_Correction' : "Change Total",
    'FORMS.Select_FurnishingOrder_Type_Order' : "Order",
    'GLOBAL.Button_Text_Logout' : 'Logout',
    'GLOBAL.Button_Text_Filter' : 'Filter',
    'GLOBAL.Button_Text_Sort' : 'Sort',
    'GLOBAL.Text_Version' : 'Version { versionNumber }',
    'GLOBAL.Button_Text_Cancel' : 'Cancel',
    'GLOBAL.Badge_Text_New' : 'NEW',
    'GLOBAL.Modal_Title_Filters' : 'Filters',
    'GLOBAL.Modal_Title_SortBy' : 'Sort by',
    'GLOBAL.TimeLeft' : '{ timeLeft } left',
    'GLOBAL.Text_Piece' : 'pcs.',
    'GLOBAL.Button_Text_Confirm' : 'Confirm',
    'GLOBAL.Card_Hero_Hall' : 'Hall',
    'GLOBAL.Card_Hero_Block' : 'Block',
    'GLOBAL.Card_Hero_Stand' : 'Stand',
    'HALL.Button_Text_HallPlan' : 'Hall plan',
    'HALL.Link_Text_HallPlan' : '{ hallName } hall plan',
    'JOB.Tag_Claim' : 'C',
    'JOB.Tag_CustomerClaim' : 'C',
    'JOB.Tag_MPAndCustomerClaim' : 'C',
    'JOB.Tag_LastMinute' : 'LM',
    'JOB.Tag_Damage' : 'D',
    'JOB.Tag_ProtocolDefect' : 'PD',
    'JOB.Card_Hero_Hall' : 'Hall',
    'JOB.Card_Hero_Number' : 'Job Nr.',
    'JOB.Card_Hero_Block' : 'Block',
    'JOB.Card_Hero_Stand' : 'Stand',
    'JOB.Card_Hero_Exhibitor' : 'Exhibitor',
    'JOB.Button_Text_ToTheStand' : 'To the stand',
    'JOB.Text_Creator' : 'by { whoCreate } on { whenCreate }',
    'JOB.Text_InWork' : 'by { whoWork } on { whenWork }',
    'JOB.Text_Executor' : 'by { whoExecute } on { whenExecute }',
    'JOB.Text_DurationTime' : 'Duration: { time }',
    'JOB.Type_Claim' : 'mp Claim',
    'JOB.Type_AllClaims' : 'Claim',
    'JOB.Type_CustomerClaim' : 'Exhibitor Claim',
    'JOB.Type_LastMinute' : 'Last minute',
    'JOB.Type_Damage' : 'Damage',
    'JOB.Type_ProtocolDefect' : 'Protocol defect',
    'JOB.Button_ChangeStatus_Waiting' : 'Take job',
    'JOB.Button_ChangeStatus_InProgress' : 'Job In progress',
    'JOB.Button_ChangeStatus_Done' : 'Finish Job',
    'JOB.Modal_Title_Photo' : 'Job photo',
    'JOB.Filter_Params_JobType' : 'Type: { withFilter }',
    'JOB.Filter_Params_CraftType' : 'Craft: { withFilter }',
    'JOB.Modal_Title_NewJob' : 'New { jobType }',
    'JOB.Modal_Title_EditJob' : 'Edit { jobType }',
    'JOB.Button_Text_AddClaim' : 'Add mp claim job',
    'JOB.Button_Text_AddCustomerClaim' : 'Add exhibitor claim job',
    'JOB.Button_Text_AddLastMinute' : 'Add last minute Job',
    'JOB.Button_Text_AddDamage' : 'Add damage photo',
    'JOB.Button_Text_AddProtocolDefect' : 'Add protocol defect',
    'DAMAGE.Card_Title' : 'Damages of { exhibitor }',
    'DAMAGE.Modal_Title_Photo' : '{ exhibitor } damages',
    'DAMAGE.Status_Label_New' : 'Unchecked',
    'DAMAGE.Status_Label_Check' : 'Checked',
    'DAMAGE.Status_Label_DCheck' : 'Approved',
    'DAMAGE.Button_Text_Stand' : 'Stand',
    'DAMAGE.Button_Text_Photo' : 'Photo',
    'DAMAGE.Button_Text_AddNote' : "ADD NOTE",
    'DAMAGE.Modal_Button_AddPhoto' : 'Add photo',
    'DAMAGE.Modal_Button_CreateReport' : 'Create report',
    'DAMAGE.Modal_Button_EditReport' : 'Edit report',
    'DAMAGE.AsCraft_label' : "Damages",
    "DAMAGE.Button_Text_Open" : "Open",
    "STAND.Block_Title_Damages" : "Damages",
    'MENUS.Label_Overview' : 'Overview',
    'MENUS.Label_Stands' : 'Stands',
    'MENUS.Label_Jobs' : 'Jobs',
    'MENUS.Label_ExpoPlan' : 'Expo Plan',
    'MENUS.Label_More' : 'More',
    'MENUS.Label_SelectExhibition' : 'Select exhibition',
    'MENUS.Label_CraftsOverview' : 'Crafts overview',
    'MENUS.Label_HallsOverview' : 'Halls overview',
    'MENUS.Label_ViewDamages' : 'View damages',
    'MENUS.Label_Downloads' : 'Downloads',
    'MENUS.Label_Documents' : 'Documents',
    'MENUS.Label_FurnishingLeftovers' : 'Furnishing leftovers',
    'MENUS.Label_HelpImprint' : 'Imprint',
    'MENUS.Label_CollectGraphics' : 'Collect Graphics',
    'NO_DATA.Exhibitions' : 'You don\'t have any exhibitions yet.',
    'NO_DATA.Documents' : 'Current exhibition don\'t have { br } any documents yet.',
    'NO_DATA.Stands' : 'Stands matching the request { br } were not found',
    'NO_DATA.StandPhoto' : 'You don\'t have stand photo yet',
    'NO_DATA.DamagePhoto' : 'You don\'t have photo of damages { br } of this stand yet',
    'NO_DATA.Stand_NoKeys' : 'This stand don\'t have any keys yet',
    'NO_DATA.Stand_DoesNotExist' : 'This stand doesn\'t exist',
    'NO_DATA.Subcontractor_Unsigned' : 'Unsigned',
    'NO_DATA.Jobs' : 'Jobs matching the request { br } were not found',
    'NO_DATA.Damages' : 'Damages matching the request { br } were not found',
    'NO_DATA.Hall' : 'This hall doesn\'t exist',
    'NO_DATA.HallPlan' : 'We have not found a plan of this hall',
    'NO_DATA.Furnishing_Storage' : 'This Exhibition doesn\'t { br } have furnishing in storage',
    'NO_DATA.Furnishing_Orders' : 'This Exhibition doesn\'t { br } have furnishing orders',
    'PAGE.Title_Overview' : 'Overview',
    'PAGE.Title_ExhibitionSelection' : 'Exhibition selection',
    'PAGE.Title_Stands' : 'Stands',
    'PAGE.Title_Stand' : 'Stand',
    'PAGE.Title_StandLog' : 'Stand Log',
    'PAGE.Title_StandKeys' : 'Stand Keys',
    'PAGE.Title_ViewDamages' : 'View Damages',
    'PAGE.Title_AdditionalMenu' : 'Additional menu',
    'PAGE.Title_Jobs' : 'Jobs',
    'PAGE.Title_ExpoPlan' : 'Expo Plan',
    'PAGE.Title_Documents' : 'Documents',
    'PAGE.Title_FurnishingLeftovers' : 'LM Furnishing',
    'PAGE.Title_FurnishingReturns' : 'Furnishing Change log',
    'PAGE.Title_HallPlan' : 'Hall Plan',
    'PAGE.Title_HallsOverview' : 'Halls overview',
    'PAGE.Title_CraftsOverview' : 'Crafts overview',
    'PAGE.Title_HelpImprint' : 'Imprint',
    'PAGE.Title_CollectGraphics' : 'Collect Graphics',
    'SORT.Text_NewestByCreation' : 'Newest by creation date',
    'SORT.Text_OldestByCreation' : 'Oldest by creation date',
    'SORT.Text_ExhibitorNameAZ' : 'Exhibitor name (A-Z)',
    'SORT.Text_ExhibitorNameZA' : 'Exhibitor name (Z-A)',
    'SORT.Text_FinishedFirst' : 'Finished first',
    'SORT.Text_InProgressFirst' : 'Open first',
    'SORT.Text_StandNumberAZ' : 'Stand number (A-Z)',
    'SORT.Text_StandNumberZA' : 'Stand number (Z-A)',
    'SORT.Text_CorridorAZ' : 'Corridor (A-Z)',
    'SORT.Text_CorridorZA' : 'Corridor (Z-A)',
    'SORT.Text_BlockAZ' : 'Block (A-Z)',
    'SORT.Text_BlockZA' : 'Block (Z-A)',
    'SORT.Text_FlagFirst' : 'With flag first',
    'SORT.Text_FlagLast' : 'With flag last',
    'STAND.Modal_Title_Search' : 'Search',
    'STAND.Input_Placeholder_Search' : 'Search',
    'STAND.Filter_Params_Exhibitor' : 'Exhibitor: { exhibitorName }',
    'STAND.Card_Hero_Hall' : 'Hall',
    'STAND.Card_Hero_Block' : 'Block',
    'STAND.Card_Hero_Stand' : 'Stand',
    'STAND.Card_Hero_DeliveryTime' : 'Delivery time',
    'STAND.Modal_Title_StandPlan' : 'Stand Drawing',
    'STAND.Modal_Button_StandPlan' : 'Stand Drawing',
    'STAND.Card_Button_Details' : 'Details { icon }',
    'STAND.Modal_Title_StandPhoto' : 'Stand Photo',
    'STAND.Modal_Button_StandPhoto' : 'Stand Photo',
    'STAND.Modal_Button_SavePhoto' : 'Save photo',
    'STAND.Modal_Button_AddPhoto' : 'Add photo',
    'STAND.Block_Title_Overview' : 'Overview',
    'STAND.Block_Title_Materials' : 'Materials',
    'STAND.Block_Title_Crafts' : 'Crafts',
    'STAND.Block_ItemTitle_Hall' : 'Hall',
    'STAND.Block_ItemTitle_Block' : 'Block',
    'STAND.Block_ItemTitle_Stand' : 'Stand',
    'STAND.Block_ItemTitle_ProaufNumber' : 'Proauf number',
    'STAND.Block_ItemTitle_Jobs' : 'Jobs',
    'STAND.Block_Item_JobCountInProgress' : '{ jobCount } in progress',
    'STAND.Block_Item_JobCountOpen' : '{ jobCount } open',
    'STAND.Block_Item_JobCountFinished' : '{ jobCount } finished',
    'STAND.Block_Button_ViewStandJobs' : 'View stand jobs',
    'STAND.Modal_Title_StandGraphic' : 'Stand Graphic',
    'STAND.Modal_Button_StandGraphic' : 'Stand Graphic',
    'STAND.Modal_Title_ExtraInfo' : 'Extra Info',
    'STAND.Modal_Button_ExtraInfo' : 'Extra Info',
    'STAND.Button_Text_ViewKeys' : 'View keys',
    'STAND.Button_Text_ViewLogs' : 'View logs',
    'STAND.Modal_Button_AddKey' : 'Add Key',
    'STAND.Modal_Title_AddKey' : 'Add Key',
    'STAND.Modal_Title_EditKey' : 'Edit key',
    'STAND.Modal_Button_UpdateKey' : 'Update key',
    'STAND.Filter_Params_Stand' : 'Stand { withFilter }',
    'STAND.Button_Text_DeleteKey' : 'Delete',
    'STAND.Text_ModalDelete_Question' : 'Are you sure you want to delete the { keyName } key?',
    'JOB.Button_Text_ViewPhoto' : 'View Photo',
    'OVERVIEW_TYPES.Label_InTotal' : 'in total',
    'OVERVIEW_TYPES.Label_Finished' : 'finished',
    'OVERVIEW_TYPES.Label_Open' : 'open',
    'OVERVIEW_TYPES.Label_Claims' : 'claims',
    'OVERVIEW_TYPES.Label_LastMinute' : 'last min',
    'OVERVIEW_TYPES.Label_Overdue' : 'overdue',
    'FURNISHING.TabName_Storage' : 'Storage',
    'FURNISHING.TabName_Refund' : 'Returns',
    'FURNISHING.TabName_OrderLog' : 'Order log',
    'FURNISHING.Modal_Title_ReturnFurnishing' : 'LM Equipment Quantities',
    'FURNISHING.Modal_Title_FurnishingStore' : 'Furnishing in the store',
    'FURNISHING.Button_Text_Return' : 'Return',
    'FURNISHING.Button_Text_ChooseFurnishing' : 'Choose furnishing',
    'FURNISHING.Modal_Title_Photo' : "Furnishing photo",
    'FURNISHING.Order_Type_Refund' : "Return",
    'FURNISHING.Order_Type_Lost' : "Lost",
    'FURNISHING.Order_Type_Correction' : "Correction",
    'FURNISHING.Order_Type_Order' : "Order",
};

export default english;