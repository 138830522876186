import { Icons } from "components/layout";

const Label = (status) => {
    switch (status)
    {
        case "done":
        case "collected":
            return "CRAFT.Status_Text_Done";
        case "open":
        default:
            return "CRAFT.Status_Text_Open";
    }
}

const Icon = (status) => {
    switch (status)
    {
        case "done":
        case "collected":
            return <Icons.Check type="double" className="collect-graphics-icon" />;
        case "open":
        default:
            return <Icons.Control className="collect-graphics-icon" />;
    }
}

export default {
    Label,
    Icon
}