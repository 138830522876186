import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_STANDS } from "graphql/query/stand-gql";

import CollectGraphicsGridItem from "./collect-graphics-grid-item";
import { Loader, NoData } from "components/request-result";
import { useMe } from "components/use-hooks";
import { Localize, OptimizedGrid } from "components/service";
import { restorePosition, whereConditionBuilder } from "utils";

const CollectGraphicsGrid = ({ filter }) => {
    const { state = {}, pathname } = useLocation(),
          { me } = useMe(),
          history = useHistory();

    const perPage = 30;

    const { data, loading, fetchMore } = useQuery(GET_STANDS, {
        variables: {
            where: whereConditionBuilder(filter.where),
            whereCrafts: whereConditionBuilder(filter.whereCrafts),
            orderBy: [{ column: "ID", order: "DESC" }],
            first: perPage,
        },
        fetchPolicy: state.pagePosition ? "cache-first" : "cache-and-network",
        notifyOnNetworkStatusChange: true
    });

    const {
        standsCursor: {
            edges: stands = [],
            pageInfo = {}
        } = {}
    } = data ? data : {};

    if (data && state.pagePosition) {
        restorePosition(history, pathname, state);
    }

    return (
        <>
            {!stands.length && !loading &&
                <NoData
                    text={{
                        after: <Localize br={<br />}>NO_DATA.Stands</Localize>
                    }}
                    icon="stand"
                />
            }
            {!!stands.length &&
                <OptimizedGrid
                    listClass="collect-graphics-grid"
                    itemClass="collect-graphics-grid-item-wrap"
                    gridItems={stands}
                    pageInfo={pageInfo}
                    fetchMore={fetchMore}
                    perPage={perPage}
                    model="standsCursor"
                >
                    <CollectGraphicsGridItem
                        me={me}
                    />
                </OptimizedGrid>
            }
            {loading && <Loader type="block" />}
        </>
    );
}

export default CollectGraphicsGrid;