import React, { useState } from "react";

import CollectGraphics from "components/collect-graphics";
import { Wraps } from "components/layout";
import { localizeIt } from "components/service/localize";
import { useMe, useVarParam } from "components/use-hooks";

const CollectGraphicsPage = () => {
    const appParams = useVarParam(),
        { me } = useMe();

    const [filter] = useState({
        where: {
            eeId: {
                column: "EE_ID",
                operator: "EQ",
                value: me.eeCurrentId
            }
        },
        whereCrafts: {
            craftType: {
                column: "TYPE",
                operator: "EQ",
                value: "collect_graphics"
            }
        }
    });

    appParams.set({
        headerTitle: localizeIt("PAGE.Title_CollectGraphics"),
        bottomBar: true
    });

    return (
        <Wraps.Container className="page collect-graphics-page">
            <CollectGraphics.Grids.All
                filter={filter}
            />
        </Wraps.Container>
    );
}

export default CollectGraphicsPage;