import CollectGraphicsBlocks from "./blocks";
import CollectGraphicsGrids from "./grids";
import CollectGraphicsHelpers from "./helpers";

const CollectGraphics = {
    Blocks  : CollectGraphicsBlocks,
    Grids   : CollectGraphicsGrids,
    Helpers : CollectGraphicsHelpers,
}

export default CollectGraphics;