import React, { useState } from "react";
import { Button, Form } from "antd";

import Hall from "components/hall";
import Craft from "components/craft";
import Localize, { localizeIt } from "components/service/localize";


const formFinish = (values, data, set, callback, changedFieldsCount) => {

    const [ hallId, hallName ] = values.hallId ? values.hallId.split(":") : [],
        [ craftStatus, craftStatusLabel ] = values.craftStatus ? values.craftStatus.split(":") : [],
        [ craftType, craftLabel ] = values.craftType ? values.craftType.split(":") : [];

    let finalCraftStatus = craftStatus;
    let finalCraftStatusLabel = craftStatusLabel;

    if (craftType === 'collect_graphics') {
        if (craftStatus === 'in_progress') {
            finalCraftStatus = 'open';
            finalCraftStatusLabel = localizeIt("CRAFT.Status_Text_Open");
        } else if (craftStatus === 'completed') {
            finalCraftStatus = 'done';
            finalCraftStatusLabel = localizeIt("CRAFT.Status_Text_Done");
        }

        if (craftStatus === 'open' || craftStatus === 'done' || craftStatus === 'collected') {
            finalCraftStatus = craftStatus;
            if (craftStatus === 'collected') {
                finalCraftStatus = 'done';
            }
            finalCraftStatusLabel = localizeIt(craftStatus === 'open'
                ? "CRAFT.Status_Text_Open"
                : "CRAFT.Status_Text_Done");
        }
    }

    set({
        ...data,
        where: {
            ...data.where,
            hallId: values.hallId ? {
                label: hallName,
                column: "HALL_ID",
                operator: "EQ",
                value: hallId,
            } : {}

        },
        whereCrafts: {
            ...data.whereCrafts,
            craftType: values.craftType ? {
                label:
                    localizeIt(
                        "JOB.Filter_Params_CraftType",
                        { withFilter: craftLabel }
                    ),
                column: "TYPE",
                operator: "EQ",
                value: craftType,
            } : {},
            craftStatus: values.craftStatus ? {
                label: finalCraftStatusLabel,
                column: "STATUS",
                operator: "EQ",
                value: finalCraftStatus,
            } : {}
        },
        activeFilters: changedFieldsCount
    })

    callback();
}


const StandFilterForm = ({ filter, callback }) => {

    const [ form ] = Form.useForm();
    const { data, set, reset } = filter,
        {
            where: { hallId },
            whereCrafts: {
                craftType,
                craftStatus,
                //subcontractorId
            },
            activeFilters
        } = data;

    const [ changedFieldsCount, setChangedFieldsCount ] = useState(activeFilters)

    const valueFieldСraftType = Form.useWatch('craftType', form);
    const extractedCraftType = valueFieldСraftType ? valueFieldСraftType.split(':')[ 0 ] : null;


    return (
        <Form
            key="stand-filter-form"
            form={form}
            layout="vertical"
            className="stand-filter-form"
            onFinish={ (values) => {
                formFinish(values, data, set, callback, changedFieldsCount);
            } }
            onValuesChange={ (changedValues, allValues) => {
                const activeFiltersCount = Object.values(allValues).filter((item) => item !== null).length;

                setChangedFieldsCount(activeFiltersCount);
            } }
        >
            <Form.Item
                name="hallId"
                label={ <Localize>FORMS.Input_Label_Hall</Localize> }
                className="dark"
                initialValue={ hallId && hallId.value ? `${ hallId.value }:${ hallId.label }` : null }
            >
                <Hall.Fields.Select />
            </Form.Item>
            <Form.Item
                name="craftType"
                label={ <Localize>FORMS.Input_Label_CraftType</Localize> }
                className="dark"
                initialValue={ craftType && craftType.value ? `${ craftType.value }:${ Craft.Helpers.Types.list(craftType.value) }` : null }
            >
                <Craft.Fields.TypeSelect />
            </Form.Item>
            <Form.Item
                name="craftStatus"
                label={ <Localize>FORMS.Input_Label_CraftStatus</Localize> }
                className="dark"
                initialValue={ craftStatus && craftStatus.value ? `${ craftStatus.value }:${ craftStatus.label }` : null }
            >
                <Craft.Fields.StatusSelect
                    craftType={ extractedCraftType }
                />
            </Form.Item>
            <div className="form-actions">
                { (!changedFieldsCount) ?
                    <Button
                        className="dark"
                        type="default"
                        onClick={ callback }
                    >
                        <Localize wrap>GLOBAL.Button_Text_Cancel</Localize>
                    </Button> :
                    <>
                        <Button
                            className="dark"
                            type="default"
                            onClick={ () => {
                                reset();
                                callback();
                            } }
                        >
                            <Localize wrap>FORMS.Button_Text_ResetFilters</Localize>
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            <Localize wrap>FORMS.Button_Text_ApplyFilters</Localize>
                        </Button>
                    </>
                }
            </div>
        </Form>
    );
}

export default StandFilterForm;