import React, { useState } from 'react';

import CollectGraphicsBlocks from '../../blocks';

const CollectGraphicsGridItem = ({
    id,
    hall_name: hallName,
    stand_number,
    exhibitor,
    crafts,
    block,
    shelf_number
}) => {
    const collectGraphicsCraft = crafts.find((craft) => craft.type === 'collect_graphics');
    const initialStatus = collectGraphicsCraft ? collectGraphicsCraft.status : 'open';
    const craftId = collectGraphicsCraft ? collectGraphicsCraft.id : null;

    const [ status, setStatus ] = useState(initialStatus);

    const stand = {
        id,
        hall_name: hallName,
        stand_number,
        exhibitor,
        block,
        shelf_number
    };

    const handleStatusChange = (newStatus) => {
        setStatus(newStatus);
    };

    return (
        <div className={ `collect-graphics-grid-item bg-light-bordered` }>
            <div className='collect-graphics-grid-item-hero grid-item-hero'>
                <div className='title'>
                    <h3>{ exhibitor || 'Unknown Exhibitor' }</h3>
                </div>
                <CollectGraphicsBlocks.Info { ...stand } />
            </div>
            <div className={ `collect-graphics-grid-item-body grey` }>
                <CollectGraphicsBlocks.Preview
                    craftId={ craftId }
                    status={ status }
                    stand={ stand }
                    withStandLink
                    onStatusChange={ handleStatusChange }
                />
            </div>
        </div>
    );
};

export default React.memo(CollectGraphicsGridItem);