import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from "@apollo/client";

import client from "graphql/client";

import App from 'components/app';
import Auth from "components/auth";
import Router from "components/routes";
import { ScrollToTop } from 'components/service';
import { RealWindowHeight } from "utils";

import 'antd/lib/style/themes/default.less';
import 'antd/dist/antd.less';
import "scss/default.scss";


createRoot( document.getElementById('root') )
    .render(
        <ApolloProvider client={ client }>
            <Router>
                <Auth>
                    <ScrollToTop />
                    <RealWindowHeight />
                    <App />
                </Auth>
            </Router>
        </ApolloProvider>
    );
