import React from "react";

import { Localize } from "components/service";

const CollectGraphicsInfoBlock = ({
    hall_name: hallName,
    stand_number: standNum,
    block,
    corridor
}) => {
    return (
        <ul className={`info grid-three-col`}>
            <li>
                <Localize wrap="h5">GLOBAL.Card_Hero_Hall</Localize>
                <p>{hallName || "—"}</p>
            </li>
            <li>
                <Localize wrap="h5">GLOBAL.Card_Hero_Block</Localize>
                <p>{block ? block : "—"}</p>
            </li>
            <li>
                <Localize wrap="h5">GLOBAL.Card_Hero_Stand</Localize>
                <p>
                    {(corridor || standNum) ?
                        `${corridor ? corridor : ""}${standNum}` : "—"
                    }
                </p>
            </li>
        </ul>
    );
};

export default CollectGraphicsInfoBlock;