import React from "react";
import { useQuery } from "@apollo/client";
import { NavLink, useLocation } from "react-router-dom";

import { GET_EE_NEW_STAND_JOBS } from "graphql/query/exhibition-gql";

import { UserHelpers } from "components/user";
import { Localize } from "components/service";
import Icons from "components/layout/icons";
import { useMe } from "components/use-hooks";

import "./main-nav-menu.scss";


const { permission } = UserHelpers;

const MainNavMenu = React.memo(() => {

    const location = useLocation(),
          { me } = useMe();

    const { data } = useQuery(GET_EE_NEW_STAND_JOBS, {
        variables: {
            eeId: me.eeCurrentId
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: 1000 * 60,
        skip: !permission().has({type: "craft_job", value: "view_job"})
    });

    const {
        countNewStandsJobs : {
            stands = 0,
            jobs = 0
        } = {}
    } = data ? data : {};

    const hasCollectGraphicsPermission = permission().has({value: "collect_graphics"});

    const isLimitedUser = hasCollectGraphicsPermission &&
                         !permission().has({type: "craft_job", value: "view_job"}) &&
                         !permission().has({value: "view_damages"});

    return(
        <nav className="main-menu-wrap">
            <ul className="main-menu text-type4">
                {!isLimitedUser && (
                    <>
                        <li>
                            <NavLink to="/" exact>
                                <Icons.Home/>
                                <Localize>MENUS.Label_Overview</Localize>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={{
                                    pathname: "/stands/",
                                    state: { from : location.pathname }
                                }}
                            >
                                <Icons.Stands/>
                                <Localize>MENUS.Label_Stands</Localize>
                                { !!stands && <span className="count-new-badge">{ stands }</span> }
                            </NavLink>
                        </li>
                        {permission().has({type: "craft_job", value: "view_job"}) &&
                            <li>
                                <NavLink
                                    to={{
                                        pathname: "/jobs/",
                                        state: { from : location.pathname }
                                    }}
                                >
                                    <Icons.Jobs/>
                                    <Localize>MENUS.Label_Jobs</Localize>
                                    { !!jobs && <span className="count-new-badge">{ jobs }</span> }
                                </NavLink>
                            </li>
                        }
                        <li>
                            <NavLink
                                to={{
                                    pathname: "/crafts-overview",
                                    state: { from : location.pathname }
                                }}
                            >
                                <Icons.Tools />
                                <Localize>MENUS.Label_CraftsOverview</Localize>
                            </NavLink>
                        </li>
                    </>
                )}
                {isLimitedUser && (
                    <li>
                        <NavLink
                            to={{
                                pathname: "/exhibitions",
                                state: { from : location.pathname }
                            }}
                        >
                            <Icons.List />
                            <Localize>MENUS.Label_SelectExhibition</Localize>
                        </NavLink>
                    </li>
                )}
                <li>
                    <NavLink
                        to={{
                            pathname: "/more/",
                            state: { from : location.pathname }
                        }}
                    >
                        <Icons.Menu/>
                        <Localize>MENUS.Label_More</Localize>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
});

export default MainNavMenu;
